/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useContext,
  useEffect,
  // useRef,
  useState,
  useMemo,
} from 'react';

import { AuthContext } from 'context/AuthContext';
import { getLocalAuthStorage } from 'utils/localStorage';

import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
import ReportPage from 'containers/ReportPage';

import { Loading } from 'components/Loading';
import { PageWrapper } from 'components/PageWrapper';
// import { InvisibleDownloadLink } from 'components/InvisibleDowloadLink';

import { PageHeader } from 'components/PageHeader';
//import { ReportContainer } from 'components/ReportContainer';
import { ReportContainerTabs } from 'components/ReportContainerTabs';
import ReportTable from 'components/Tables/ReportTable';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { DownloadButton } from 'components/Tables/ReportTable/styles';

import { Toast } from 'components/Toast';

import { ReportService } from 'services/reports';

import { createGenericErrorToast } from 'utils/createToast';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import { colors } from 'assets/styled/tokens';
import { Spinner } from 'components/Spinner';
import {
  // StyledOnlyCurrentCorrectionStatus
  Header,
  StatusInfo,
  StatusWarningInfo,
  StyledWarningIcon,
  RedoReportButton,
} from './styled';

const OccurrenceGeneratedPDF = () => {
  const { authContext } = useContext(AuthContext);
  const { user, token } = authContext;
  const authStorage = getLocalAuthStorage();
  const { globalComponentsContext, setGlobalComponentsContext } = useContext(GlobalComponentsContext);
  const { isLoading } = globalComponentsContext;
  const [toastList, setToastList] = useState([]);
  const [fields, setFields] = useState({
    generatedReports: [],
  });
  const getFirstColumnFields = async () => {
    return Promise.all([
      ReportService.getGeneratedReports({
        usuarioId: user.id,
        tipos: [6],
        token: authStorage.token,
      }),
    ]).then(values => {
      return {
        generatedReports: values[0]?.data.sort((a, b) => {
          return a.emissao > b.emissao ? -1 : a.emissao < b.emissao ? 1 : 0;
        }),
      };
    });
  };

  const populateFields = async () => {
    try {
      const fieldsData = await getFirstColumnFields();
      setFields(prevState => ({
        ...prevState,
        ...fieldsData,
      }));
    } catch (e) {
      createGenericErrorToast(setToastList);
    }
  };

  const fetchData = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      await populateFields();
    } catch (err) {
      createGenericErrorToast(setToastList);
    } finally {
      setGlobalComponentsContext(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);
  const columns = useMemo(
    () => [
      {
        Header: 'Arquivo',
        accessor: 'arquivo',
      },
      {
        Header: 'Data/hora de emissão',
        accessor: 'dataHoraEmissao',
      },
    ],
    [],
  );

  const downloadReport = async (generatedReport, e) => {
    e.preventDefault();
    const result = await ReportService.getUrlToDownload({
      token,
      nomeArquivo: generatedReport.nomeArquivo,
      tipo: generatedReport.tipo,
    });
    window.open(result.data);
    return false;
  };
  const getDate = emissao => {
    const dateString = new Date(emissao);
    const date = dateString.toLocaleDateString();
    const time = dateString.toLocaleTimeString();
    return `${date} • ${time} `;
  };
  const handleRedoReport = async (id, e) => {
    e.preventDefault();
    await ReportService.redoReport({ id, token });
    updateGeneratedReports(e);
  };
  const dataTable = fields?.generatedReports?.map(generatedReport => ({
    arquivo:
      generatedReport.status === 2 ? (
        <div className="download-report">
          <DownloadIcon />
          <DownloadButton onClick={e => downloadReport(generatedReport, e)}>
            <b>Baixar Relatório{generatedReport.tipo === 2 ? ' (EXCEL)' : ' (PDF)'}</b>
          </DownloadButton>
        </div>
      ) : generatedReport.status === 4 ? (
        <div className="download-report">
          <StyledWarningIcon />
          <StatusWarningInfo>
            ERRO: TENTE NOVAMENTE
            <RedoReportButton onClick={e => handleRedoReport(generatedReport.id, e)}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" stroke="#E5E5E5" />
                <path
                  d="M21.7144 10H20.5716C20.4287 10 20.2859 10.1429 20.2859 10.2857C20.2859 10.3095 20.2859 10.3095 20.2859 10.3095L20.3811 12.2857C19.3097 11 17.6906 10.1905 15.9049 10.1905C12.643 10.1905 9.97635 12.8571 10.0002 16.119C10.0002 19.381 12.643 22 15.9049 22C17.4287 22 18.8097 21.4286 19.8573 20.5C19.9049 20.4286 19.9525 20.3571 19.9525 20.2857C19.9525 20.1905 19.9049 20.119 19.8573 20.0714L19.0478 19.2619C19.0002 19.2143 18.9287 19.1905 18.8573 19.1905C18.7859 19.1905 18.7144 19.2143 18.6668 19.2619C17.9287 19.9048 16.9525 20.2857 15.9049 20.2857C13.5716 20.2857 11.7144 18.4286 11.7144 16.0952C11.7144 13.7857 13.5716 11.9048 15.9049 11.9048C17.3335 11.9048 18.6192 12.6429 19.3811 13.7619L16.9525 13.6429C16.9525 13.6429 16.9525 13.6429 16.9287 13.6429C16.7859 13.6429 16.643 13.7857 16.643 13.9286H16.6668V15.0714C16.6668 15.2143 16.7859 15.3571 16.9525 15.3571H21.7144C21.8573 15.3571 22.0002 15.2143 22.0002 15.0714V10.2857C22.0002 10.1429 21.8573 10 21.7144 10Z"
                  fill="#1E1E1E"
                />
              </svg>
            </RedoReportButton>
          </StatusWarningInfo>
        </div>
      ) : generatedReport.status === 5 ? (
        <div className="download-report">
          <StyledWarningIcon />
          <StatusWarningInfo>ERRO: SEM DADOS P/ EMISSÃO</StatusWarningInfo>
        </div>
      ) : generatedReport.status === 6 ? (
        <div className="download-report">
          <StyledWarningIcon />
          <StatusWarningInfo> agendado para mais tarde</StatusWarningInfo>
          <GenericTooltip
            bottom={'25px'}
            left={'-215%'}
            afterLeft={'50%'}
            afterTop={'92%'}
            top="92%"
            text="Este relatório é muito grande e será gerado em um horário de menor demanda para garantir o melhor desempenho do sistema. Você receberá um aviso por e-mail assim que ele estiver disponível para download."
          />
        </div>
      ) : (
        <div className="download-report">
          <Spinner />
          <StatusInfo>GERANDO RELATÓRIO</StatusInfo>
        </div>
      ),
    dataHoraEmissao: getDate(generatedReport.emissao),
    unidade: generatedReport.unidades,
    ocorrencias: generatedReport.ocorrencias,
  }));
  const updateGeneratedReports = async e => {
    e.preventDefault();
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    const fieldsData = await ReportService.getGeneratedReports({
      usuarioId: user.id,
      tipos: [6],
      token: authStorage.token,
    }).then(values => ({
      generatedReports: values?.data.sort((a, b) => {
        return a.emissao > b.emissao ? -1 : a.emissao < b.emissao ? 1 : 0;
      }),
    }));
    setFields(prevState => ({
      ...prevState,
      ...fieldsData,
    }));
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  };
  const updateGeneratedReports60S = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    const fieldsData = await ReportService.getGeneratedReports({
      usuarioId: user.id,
      tipos: [6],
      token: authStorage.token,
    }).then(values => ({
      generatedReports: values?.data.sort((a, b) => {
        return a.emissao > b.emissao ? -1 : a.emissao < b.emissao ? 1 : 0;
      }),
    }));
    setFields(prevState => ({
      ...prevState,
      ...fieldsData,
    }));
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  };
  const tabs = [
    {
      id: 0,
      title: 'Relatórios gerados',
      content: (
        <>
          <ReportTable
            title="Histórico dos últimos 90 dias:"
            columns={columns}
            data={dataTable}
            updateGeneratedReports={updateGeneratedReports}
          />
        </>
      ),
      propertyContent: 'tabela',
    },
  ];

  const activeTab = useState(tabs[0]);
  const [autoReload, setAutoReload] = useState(tabs[0].id);
  useEffect(() => {
    const intervalUpdate = setInterval(async () => {
      if (fields.generatedReports.some(element => element.status === 1 || element.status == 3) && autoReload === 1) {
        await updateGeneratedReports60S();
      }
    }, 60000);
    return () => clearInterval(intervalUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoReload]);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  return (
    <>
      {isLoading && <Loading />}
      <ReportPage setIsSubMenuOpen={setIsSubMenuOpen}>
        <PageWrapper background={colors.pampas} isSubMenuOpen={isSubMenuOpen}>
          <HeaderFiltersPage>
            <Header>
              <PageHeader title="Relatório - PDF de Ocorrências" />
            </Header>
            <ReportContainerTabs
              tabs={tabs}
              activeTab={activeTab[0].id}
              //   exportTitle="Exportar Relatório"
              setAutoReload={setAutoReload}
              //   isPageDisabled={isPageDisabled}
              //   onSubmit={handleSubmit(onSubmit)}
              //   currentSavedFilter={currentSavedFilter?.filterName}
              //   useSavedFilters={openModalSavedFilters}
              //   cleanSavedFilter={cleanFields}
              //   saveFilter={saveFilters}
              //   activeAfterSendEmail={active}
              //   setActiveAfterSendEmail={setActive}
              //   //onBack={goback}
              //   cleanFields={cleanFields}
              //   refInputFilterName={register({
              //     required: true,
              //   })}
              //   formId={reportName}
              //   renderFooter={watch('units')?.length > 0}
              //   emailNotVerifiedElement={emailNotVerifiedElement}
              isNotPdfOcurrence={false}
            >
              {tabs}
            </ReportContainerTabs>
          </HeaderFiltersPage>
        </PageWrapper>
        {/* <InvisibleDownloadLink
            ref={downloadLinkElement}
            href={downloadReport?.href}
            download={downloadReport?.name}
          /> */}
        <Toast toastList={toastList} autoDelete dismissTime={5000} />
      </ReportPage>
    </>
  );
};

export default OccurrenceGeneratedPDF;
