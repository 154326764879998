import { GridColumn, Loading } from 'components';
import React, { useContext, useState, useMemo } from 'react';
import { FiltersContext } from 'context/GlobalFiltersContext';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import { AuthContext } from 'context/AuthContext';
import { getLocalAuthStorage } from 'utils/localStorage';
import { useHistory, useLocation } from 'react-router-dom';
import { Radio } from 'components/Form/subcomponents/Radio';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as EmailSuccessIcon } from 'assets/icons/rel-success-icon.svg';
import { ReactComponent as EmailAtentionIcon } from 'assets/icons/atention-icon.svg';
import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
import { PageWrapper } from 'components/PageWrapper';
import { MobileNavbar } from 'components/MobileNavbar';
//import { Menu } from 'components/Menu';
import { colors } from 'assets/styled/tokens';
import MultiSelectExcel from './subcomponents/MultiSelect';
import {
  GoBackButton,
  Header,
  HeaderName,
  KnowMoreButton,
  StyledActions,
  StyledContainerFooter,
  StyledFooter,
  StyledContentWrapper,
  ExportButton,
  SendEmailModal,
  DownloadButton,
  EmailNotVerifiedWarningContainer,
  StyledFormRadio,
  PeriodWarning,
  AtentionModal,
  RequiredMessage,
  StatusInfo,
  StatusWarningInfo,
  StyledWarningIcon,
  RedoReportButton,
  InfoContainer,
} from './styled';
import * as Sentry from '@sentry/react';
import { ReportContainerTabs } from 'components/ReportContainerTabs';
import { ReportContainerColumn } from 'components/ReportContainer/subcomponents/ReportContainerColumn';
import { ReportContainerFields } from 'components/ReportContainer/subcomponents/ReportContainerFields';
import { FieldRow } from 'components/Form/subcomponents/FieldRow';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import { Controller, useForm } from 'react-hook-form';
import { Select } from 'components/Form/subcomponents/Select';
import MultiSelect from 'components/Form/subcomponents/MultiSelect';
import ReportTable from 'components/Tables/ReportTable';
import { useEffect } from 'react';
import { ActivitiesServices, FiltersService, ReportService } from 'services/reports';
import { createErrorToast, createGenericErrorToast, createToast } from 'utils/createToast';
import { adaptReportsApiOptions, adaptReportsApiOptionsSubgroups } from 'utils/helpers/adapters';
import { LineSeparator } from 'components/ReportContainer/subcomponents/LineSeparator';
import Conditional from 'components/Conditional';
import Accordion from 'components/Accordion';
import { DateRangePicker } from 'components/Form/subcomponents/DateRangePicker';
import { formatDateRangeValue } from 'utils/formatDateRangeValue';
import { Toast } from 'components/Toast';
import { Button } from 'components/Button';
import { MultiCheckbox } from 'components/Form/subcomponents/MultiCheckbox';
import { ReportContainerFillFields } from 'components/ReportContainer/subcomponents/ReportContainerFillFields';
import { Spinner } from 'components/Spinner';
import { NewMenu } from 'components/Menu/NewMenu';
import { Tooltip } from './subcomponents/Tooltip';
import { GenericTooltip } from 'components/Tooltip/GenericTooltip';
const Activities = ({ pathname }) => {
  const { authContext } = useContext(AuthContext);
  const { user, token } = authContext;
  const { globalComponentsContext, setGlobalComponentsContext } = useContext(GlobalComponentsContext);
  const { isLoading } = globalComponentsContext;
  const [isSendOcurrenceEmailSuccess, setIsSendOcurrenceEmailSuccess] = useState(false);
  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;
  const authStorage = getLocalAuthStorage();
  const [isFirstMount, setIsFirstMount] = useState(true);
  const [toastList, setToastList] = useState([]);
  const [statusRequired, setStatusRequired] = useState(false);
  const [dateRequired, setDateRequired] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [duplicatedReport, setDuplicatedReport] = useState(false);
  const [active, setActive] = useState(false);
  const [fields, setFields] = useState({
    unitGroups: [],
    unitSubgroups: [],
    units: [],
    //responsibles: [],
    //responsiblesARTCertificateDocuments: undefined,
    //responsiblesTRTCertificateDocuments: undefined,
    //showEquipmentsImages: true,
    //showAditionalInformation: true,
    //showJustificationsOfNonRealizedActivities: true,
    //extraDocuments: [],
    systems: [],
    generatedReports: [],
    activitiesPlans: [],
    people: {
      providers: [],
      executors: [],
    },
    local: {
      areaGroups: [],
      areaSubgroups: [],
      areas: [],
      equipmentTypes: [],
      equipments: [],
    },
  });
  const statusOptions = [
    {
      label: 'Pendente',
      value: 1,
    },
    {
      label: 'Aguardando Aprovação',
      value: 2,
    },
    {
      label: 'Realizado',
      value: 3,
    },
    {
      label: 'Não Realizado',
      value: 4,
    },
    {
      label: 'Baixado Automaticamente',
      value: 5,
    },
  ];
  const checkBoxOptionsSummary = [
    {
      label: 'Assinaturas',
    },
    {
      label: 'Fotos',
    },
  ];
  const checkBoxOptionsDetailed = [
    {
      label: 'Justificativas de atividades não realizadas',
    },
    {
      label: 'Medições',
    },
    {
      label: 'Materiais utilizados',
    },
    {
      label: 'Custos',
    },
    {
      label: 'Assinaturas',
    },
    {
      label: 'Fotos',
    },
  ];
  const goback = () => history.goBack();
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      unitGroups: headerFilters?.unitGroups?.id ? [headerFilters?.unitGroups?.id] : [],
      unitSubgroups: headerFilters?.unitSubgroups?.id ? [headerFilters?.unitSubgroups?.id] : [],
      units: headerFilters?.units?.id ? [headerFilters?.units?.id] : [],
      reportType: 'pdf',
      reportModel: 'detailed',
      inactiveActivities: 'false',
      appPerPages: 'true',
      groupPhotos: 'yes',
      photoSize: 'normal',
      activities: {
        status: ['1', '2', '3', '4', '5'] || [],
      },
    },
  });
  const resetFieldValues = () => {
    setValue('unitGroup', '');

    setValue('unitSubgroup', '');

    setValue('unit', '');
    setValue('units', ['']);

    setValue('systems', []);
    setValue('activitiesPlans', []);

    setValue('period', '');
    fetchData();
  };
  const [unitId, setUnitId] = useState(headerFilters?.units?.id || '');
  const [unitsId, setUnitsId] = useState([headerFilters?.units?.id] || ['']);

  const populateFieldsStrategy = async () => {
    const companyId = headerFilters?.companies?.id;
    const unitGroupId = getValues('unitGroup');
    const unitSubgroupId = getValues('unitSubgroup');
    const providerIds = getValues('people.providers');
    const areaGroupIds = getValues('place.areaGroup');
    const areaSubgroupIds = getValues('place.areaSubgroup');
    const areaIds = getValues('place.area');
    const equipmentTypeIds = getValues('place.equipmentTypes');
    /*const inactivePlans = getValues('inactiveActivities');*/
    setUnitId(getValues('unit'));
    setUnitsId(getValues('units'));
    const unitType = watch('reportType') === 'excel' ? unitsId : unitId;
    const systems = getValues('systems');
    if (!unitType) {
      return await Promise.all([
        FiltersService.getUnitGroups({
          companyId,
          ids: unitGroupId && [unitGroupId],
        }),
        FiltersService.getUnitSubgroups({
          companyId,
          ids: unitSubgroupId && [unitSubgroupId],
          unitGroupIds: unitGroupId && [unitGroupId],
        }),
        FiltersService.getUnits({
          companyId,
          ids: watch('reportType') === 'excel' ? unitsId : unitId && [unitId],
          unitGroupIds: unitGroupId && [unitGroupId],
          unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
        }),
        ReportService.getGeneratedReports({
          usuarioId: user.id,
          tipos: [3, 4],
          token: authStorage.token,
        }),
      ]).then(values => ({
        unitGroups: values[0]?.data.map(({ nome, grupoUnidadeId }) => ({
          label: nome,
          value: grupoUnidadeId,
        })),
        unitSubgroups: values[1]?.data.map(({ nome, subGrupoUnidadeId }) => ({
          label: nome,
          value: subGrupoUnidadeId,
        })),
        units: values[2]?.data.map(({ nome, site }) => ({
          label: nome,
          value: site,
        })),
        generatedReports: values[3]?.data.sort((a, b) => {
          return a.emissao > b.emissao ? -1 : a.emissao < b.emissao ? 1 : 0;
        }),
      }));
    }
    return await Promise.all([
      FiltersService.getUnitGroups({
        companyId,
        ids: unitGroupId && [unitGroupId],
      }),
      FiltersService.getUnitSubgroups({
        companyId,
        ids: unitSubgroupId && [unitSubgroupId],
        unitGroupIds: unitGroupId && [unitGroupId],
      }),
      FiltersService.getUnits({
        companyId,
        ids: watch('reportType') === 'excel' ? unitsId : unitId && [unitId],
        unitGroupIds: hasOnlyUnitsWithGroup ? unitGroupId && [unitGroupId] : '',
        unitSubgroupIds: hasOnlyUnitsWithSubgroup ? unitSubgroupId && [unitSubgroupId] : '',
      }),
      FiltersService.getSystems({
        companyId,
        unitIds: watch('reportType') === 'excel' ? unitsId : unitId && [unitId],
        unitGroupIds: unitGroupId && [unitGroupId],
        unitSubgroupIds: unitSubgroupId && [unitSubgroupId],
      }),
      ActivitiesServices.getActivitiesPlans({
        companyId,
        systemIds: systems,
        unitId: watch('reportType') === 'excel' ? unitsId : unitId,
        /*inactivePlans,*/
      }),
      ReportService.getGeneratedReports({
        usuarioId: user.id,
        tipos: [3, 4],
        token: authStorage.token,
      }),
      unitId || unitsId.length > 0
        ? FiltersService.getProviders({
            companyId,
            unitIds: watch('reportType') === 'excel' ? unitsId : unitId && [unitId],
          })
        : Promise.resolve(null),
      FiltersService.getUsers({
        companyId,
        unitIds: watch('reportType') === 'excel' ? unitsId : typeof unitId === 'object' ? unitId : [unitId],
        providerIds,
      }),
      FiltersService.getAreaGroups({
        companyId,
        unitIds: watch('reportType') === 'excel' ? unitsId : typeof unitId === 'object' ? unitId : [unitId],
        providerIds,
        areaIds,
        areaSubgroupIds,
      }),
      FiltersService.getAreaSubgroups({
        companyId,
        unitIds: watch('reportType') === 'excel' ? unitsId : typeof unitId === 'object' ? unitId : [unitId],
        providerIds,
        areaGroupIds,
        areaIds,
      }),
      FiltersService.getAreas({
        companyId,
        ids: areaIds,
        unitIds: watch('reportType') === 'excel' ? unitsId : typeof unitId === 'object' ? unitId : [unitId],
        providerIds,
        areaGroupIds,
        areaSubgroupIds,
      }),
      FiltersService.getEquipmentTypes({ companyId }),
      FiltersService.getEquipments({
        companyId,
        unitIds: watch('reportType') === 'excel' ? unitsId : typeof unitId === 'object' ? unitId : [unitId],
        providerIds,
        areaGroupIds,
        areaSubgroupIds,
        areaIds,
        equipmentTypeIds,
      }),
    ]).then(values => ({
      unitGroups: values[0]?.data.map(({ nome, grupoUnidadeId }) => ({
        label: nome,
        value: grupoUnidadeId,
      })),
      unitSubgroups: values[1]?.data.map(({ nome, subGrupoUnidadeId }) => ({
        label: nome,
        value: subGrupoUnidadeId,
      })),
      units: values[2]?.data.map(({ nome, site }) => ({
        label: nome,
        value: site,
      })),
      systems: values[3]?.data.map(({ nome, sistemaEmpresa }) => ({
        label: nome,
        value: sistemaEmpresa,
      })),
      activitiesPlans: values[4]?.data?.map(({ nome, planoId }) => ({
        label: nome,
        value: planoId,
      })),
      generatedReports: values[5]?.data.sort((a, b) => {
        return a.emissao > b.emissao ? -1 : a.emissao < b.emissao ? 1 : 0;
      }),
      people: {
        providers:
          values[6]?.data?.map(({ id, fantasyName }) => ({
            label: fantasyName,
            value: id,
          })) || [],
        executors: values[7].data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      },
      local: {
        areaGroups: values[8]?.data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        areaSubgroups: values[9]?.data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        areas: values[10]?.data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        equipmentTypes: values[11]?.data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        equipments: values[12]?.data.map(({ id, tag, name }) => ({
          label: `${tag} - ${name}`,
          value: id,
        })),
      },
    }));
  };
  const checkUnitsSubgroup = (units, fields, unitSubgroups) => {
    const adaptedUnits = adaptReportsApiOptions(units);
    fields.sort((a, b) => {
      return a.value - b.value;
    });
    adaptedUnits.sort((a, b) => {
      return a.value - b.value;
    });
    if (fields.length === adaptedUnits.length) {
      let isEqual = false;
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].value === adaptedUnits[i].value) {
          isEqual = true;
        } else {
          isEqual = false;
          break;
        }
      }
      if (isEqual) {
        //setHasOnlyUnitsWithSubgroup(true);
        setValue('unitSubgroup', unitSubgroups[0].value);
        return adaptedUnits;
      } else {
        setHasOnlyUnitsWithSubgroup(false);
        setValue('unitSubgroup', '');
        return fields;
      }
    } else {
      setHasOnlyUnitsWithSubgroup(false);
      setValue('unitSubgroup', '');
      return fields;
    }
  };
  const checkUnitsGroup = (units, fields, unitGroups) => {
    const adaptedSubgroups = adaptReportsApiOptionsSubgroups(units);
    fields.sort((a, b) => {
      return a.value - b.value;
    });
    adaptedSubgroups.sort((a, b) => {
      return a.value - b.value;
    });
    if (fields.length === adaptedSubgroups.length) {
      let isEqual = false;
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].value === adaptedSubgroups[i].value) {
          isEqual = true;
        } else {
          isEqual = false;
          break;
        }
      }
      if (isEqual) {
        //setHasOnlyUnitsWithSubgroup(true);
        setValue('unitGroup', unitGroups[0].value);
        return adaptedSubgroups;
      } else {
        setHasOnlyUnitsWithGroup(false);
        setValue('unitGroup', '');
        return fields;
      }
    } else {
      setHasOnlyUnitsWithGroup(false);
      setValue('unitGroup', '');
      return fields;
    }
  };
  const populateFields = async () => {
    try {
      const fieldsData = await populateFieldsStrategy();
      setFields(prevState => ({
        ...prevState,
        activitiesPlans: [],
      }));
      const { unitSubgroups, unitGroups } = fieldsData;
      if (unitGroups?.length === 1) {
        const { data: subGroups } = await FiltersService.getUnitSubgroups({
          companyId: headerFilters?.companies?.id,
          unitGroupIds: unitGroups[0]?.value,
        });
        const checkedSubGroups = checkUnitsGroup(subGroups, fieldsData.unitSubgroups, unitGroups);
        fieldsData.unitSubgroups = checkedSubGroups;
      }
      if (unitSubgroups?.length === 1) {
        const { data: units } = await FiltersService.getUnits({
          companyId: headerFilters?.companies?.id,
          unitGroupIds: unitGroups[0]?.value,
          unitSubgroupIds: hasOnlyUnitsWithSubgroup ? unitSubgroups[0].value : '',
        });
        const checkedUnits = checkUnitsSubgroup(units, fieldsData.units, unitSubgroups);
        fieldsData.units = checkedUnits;
      }
      setFields(prevState => ({
        ...prevState,
        ...fieldsData,
      }));
    } catch (err) {
      createGenericErrorToast(setToastList);
    }
  };
  const fetchData = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      const login = user?.name;
      const { token } = authStorage;
      const { data } = await ActivitiesServices.validateLogin(login, token);
      if (data === 'Email Valido') {
        setIsEmailVerified(true);
      }
      await populateFields();
    } catch (err) {
      if (err.message.includes('Network Error')) {
        history.push('/conection-error');
      }
      createGenericErrorToast(setToastList);
    } finally {
      setGlobalComponentsContext(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
    if (!headerFilters?.companies?.id) {
      createErrorToast(setToastList, 'Selecione uma empresa para continuar');
      return;
    }
  };
  useEffect(() => {
    fetchData();
    setIsFirstMount(false);
    // eslint-disable-next-line
  }, [errors, unitId, unitsId]);
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'Arquivo',
        accessor: 'arquivo',
      },
      {
        Header: 'Data/hora de emissão',
        accessor: 'dataHoraEmissao',
      },
      {
        Header: 'Unidade(s)',
        accessor: 'unidade',
      },
      {
        Header: 'Período',
        accessor: 'periodo',
      },
    ],
    [],
  );
  const downloadReport = async (generatedReport, e) => {
    e.preventDefault();
    const result = await ReportService.getUrlToDownload({
      token,
      nomeArquivo: generatedReport.nomeArquivo,
      tipo: generatedReport.tipo,
    });
    window.open(result.data);
    return false;
  };
  const getDate = emissao => {
    const dateString = new Date(emissao);
    const date = dateString.toLocaleDateString();
    const time = dateString.toLocaleTimeString();
    return `${date} • ${time} `;
  };
  const handleRedoReport = async (id, e) => {
    e.preventDefault();
    await ReportService.redoReport({ id, token });
    updateGeneratedReports(e);
  };
  const dataTable = fields?.generatedReports?.map(generatedReport => {
    let arquivo;
    const listaArquivos = generatedReport.listaArquivos ? JSON.parse(generatedReport.listaArquivos) : null;

    if (generatedReport.status === 2) {
      if (listaArquivos) {
        arquivo = (
          <>
            {listaArquivos.map((report, key) => (
              <div className="download-report" key={key}>
                <DownloadIcon />
                <DownloadButton onClick={e => downloadReport({ nomeArquivo: report, tipo: generatedReport.tipo }, e)}>
                  <b>Baixar Relatório{generatedReport.tipo === 3 ? ' (EXCEL)' : ' (PDF)'}</b>
                </DownloadButton>
              </div>
            ))}
            <InfoContainer>
              {generatedReport.quantidadeAtividades} atividades. {generatedReport.quantidadeFotos} fotos.
              {generatedReport.resolucaoFotoAlterada && (
                <div>
                  *Fotos com resolução alterada{' '}
                  <Tooltip
                    top="86%"
                    text="Devido ao grande número de fotos, sua resolução foi reduzida para gerar um relatório com peso otimizado."
                  />{' '}
                </div>
              )}
            </InfoContainer>
          </>
        );
      } else {
        arquivo = (
          <>
            <div className="download-report">
              <DownloadIcon />
              <DownloadButton onClick={e => downloadReport(generatedReport, e)}>
                <b>Baixar Relatório{generatedReport.tipo === 3 ? ' (EXCEL)' : ' (PDF)'}</b>
              </DownloadButton>
            </div>
            <InfoContainer>
              {generatedReport.quantidadeAtividades} atividades. {generatedReport.quantidadeFotos} fotos.
              {generatedReport.resolucaoFotoAlterada && (
                <div>
                  *Fotos com resolução alterada{' '}
                  <Tooltip
                    top="86%"
                    text="Devido ao grande número de fotos, sua resolução foi reduzida para gerar um relatório com peso otimizado."
                  />{' '}
                </div>
              )}
            </InfoContainer>
          </>
        );
      }
    } else if (generatedReport.status === 4) {
      arquivo = (
        <div className="download-report">
          <StyledWarningIcon />
          <StatusWarningInfo>
            ERRO: TENTE NOVAMENTE
            <RedoReportButton onClick={e => handleRedoReport(generatedReport.id, e)}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" stroke="#E5E5E5" />
                <path
                  d="M21.7144 10H20.5716C20.4287 10 20.2859 10.1429 20.2859 10.2857C20.2859 10.3095 20.2859 10.3095 20.2859 10.3095L20.3811 12.2857C19.3097 11 17.6906 10.1905 15.9049 10.1905C12.643 10.1905 9.97635 12.8571 10.0002 16.119C10.0002 19.381 12.643 22 15.9049 22C17.4287 22 18.8097 21.4286 19.8573 20.5C19.9049 20.4286 19.9525 20.3571 19.9525 20.2857C19.9525 20.1905 19.9049 20.119 19.8573 20.0714L19.0478 19.2619C19.0002 19.2143 18.9287 19.1905 18.8573 19.1905C18.7859 19.1905 18.7144 19.2143 18.6668 19.2619C17.9287 19.9048 16.9525 20.2857 15.9049 20.2857C13.5716 20.2857 11.7144 18.4286 11.7144 16.0952C11.7144 13.7857 13.5716 11.9048 15.9049 11.9048C17.3335 11.9048 18.6192 12.6429 19.3811 13.7619L16.9525 13.6429C16.9525 13.6429 16.9525 13.6429 16.9287 13.6429C16.7859 13.6429 16.643 13.7857 16.643 13.9286H16.6668V15.0714C16.6668 15.2143 16.7859 15.3571 16.9525 15.3571H21.7144C21.8573 15.3571 22.0002 15.2143 22.0002 15.0714V10.2857C22.0002 10.1429 21.8573 10 21.7144 10Z"
                  fill="#1E1E1E"
                />
              </svg>
            </RedoReportButton>
          </StatusWarningInfo>
        </div>
      );
    } else if (generatedReport.status === 5) {
      arquivo = (
        <div className="download-report">
          <StyledWarningIcon />
          <StatusWarningInfo>NÃO GERADO (SEM DADOS) </StatusWarningInfo>
          <GenericTooltip
            left={'-217%'}
            bottom={'25px'}
            afterLeft={'49%'}
            afterTop={'86%'}
            text="Não há dados para os filtros selecionados. Tente gerar o relatório novamente utilizando outros filtros."
          />
        </div>
      );
    } else if (generatedReport.status === 6) {
      arquivo = (
        <div className="download-report">
          <StyledWarningIcon />
          <StatusWarningInfo> agendado para mais tarde</StatusWarningInfo>
          <Tooltip
            top="92%"
            text="Este relatório é muito grande e será gerado em um horário de menor demanda para garantir o melhor desempenho do sistema. Você receberá um aviso por e-mail assim que ele estiver disponível para download."
          />
        </div>
      );
    } else {
      arquivo = (
        <div className="download-report">
          <Spinner />
          <StatusInfo>GERANDO RELATÓRIO</StatusInfo>
        </div>
      );
    }

    return {
      arquivo,
      dataHoraEmissao: getDate(generatedReport.emissao),
      unidade: generatedReport.unidades,
      periodo: generatedReport.periodo,
    };
  });

  const validateRequiredFieldsExcel = values => {
    const period = values.period;
    const units = values.units;
    const expectedDate = formatDateRangeValue(period.expectedDate);
    if (units.length === 0) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Por favor selecione uma ou mais unidades.',
        }),
      ]);
      return true;
    }
    if (!getValues('period.expectedDate')) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Por favor preencha o periódo.',
        }),
      ]);
      return true;
    }
    const timeDifference = new Date(expectedDate.end).getTime() - new Date(expectedDate.start).getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    if (units.length > 1 && daysDifference > 31) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Selecione um período inferior a 31 dias.',
        }),
      ]);
      return true;
    }
    return false;
  };
  const formatPayloadExcel = values => {
    const { period } = values;
    const expectedDate = formatDateRangeValue(period.expectedDate);
    return {
      ...values,
      activitiesPlans: values.activities.plan.map(plan => Number(plan)),
      companyId: headerFilters?.companies?.id,
      units: values?.units.map(unit => Number(unit)),
      unitGroups: values?.unitGroups,
      unitSubgroups: values?.unitSubgroups,
      systems: fields?.systems
        .filter(system => values.systems.includes(`${system.value}`))
        .map(system => Number(system.value)),
      period: {
        expectedDate,
      },
      status: values.activities.status.map(status => Number(status)),
      inactivePlans: !values?.inactiveActivities.includes('false'),
    };
  };
  const validateRequiredFieldsPDF = values => {
    const { period, activities } = values;
    if (!period.accomplishedDate && !period.expectedDate) {
      // setToastList(prevState => [
      //   ...prevState,
      //   createToast({
      //     type: 'error',
      //     message: 'Por favor preencha um período.',
      //   }),
      // ]);
      return true;
    }
    if (activities.status.length <= 0) {
      // setToastList(prevState => [
      //   ...prevState,
      //   createToast({
      //     type: 'error',
      //     message: 'Por favor preencha um status.',
      //   }),
      // ]);
      return true;
    }
    return false;
  };
  const formatPhotoValues = values => {
    const photos = values.activities?.multiCheckbox.some(checkBox => checkBox.label.includes('Fotos'));
    let photosValue = 0;
    if (photos) {
      photosValue = 2;
    }
    return photosValue;
  };
  const formatPayloadPDF = values => {
    const { period } = values;
    const expectedDate = formatDateRangeValue(period.expectedDate);
    const accomplishedDate = formatDateRangeValue(period.accomplishedDate);
    return {
      ...values,
      notGroupPhotos: !values?.groupPhotos?.includes('yes'),
      multiCheckbox: {
        justifications: values.activities?.multiCheckbox?.some(checkBox =>
          checkBox?.label?.includes('Justificativas de atividades não realizadas'),
        ),
        measurements: values.activities?.multiCheckbox?.some(checkBox => checkBox?.label?.includes('Medições')),
        materials: values.activities?.multiCheckbox?.some(checkBox =>
          checkBox?.label?.includes('Materiais utilizados'),
        ),
        cost: values.activities?.multiCheckbox?.some(checkBox => checkBox?.label?.includes('Custos')),
        signatures: values.activities?.multiCheckbox?.some(checkBox => checkBox?.label?.includes('Assinaturas')),
      },
      photos: formatPhotoValues(values),
      activitiesPlans: values.activities.plan.map(plan => Number(plan)),
      companyId: headerFilters?.companies?.id,
      units: values?.unit,
      unitGroups: values?.unitGroup,
      unitSubgroups: values?.unitSubgroup,
      systems: fields?.systems
        .filter(system => values.systems.includes(`${system.value}`))
        .map(system => Number(system.value)),
      period: {
        expectedDate,
        accomplishedDate,
      },
      status: values.activities.status.map(status => Number(status)),
      people: {
        providers: fields?.people?.providers
          .filter(provider => values.people.providers === provider.value)
          .map(provider => provider.value),
        executors: fields?.people?.executors
          .filter(executor => values.people.executors.includes(`${executor.value}`))
          .map(executor => executor.value),
      },
      place: {
        areas: values?.place?.area.map(area => Number(area)),
        areaGroups: fields?.local?.areaGroups
          .filter(areaGroup => values.place.areaGroup.includes(`${areaGroup.value}`))
          .map(areaGroup => areaGroup.value),
        areaSubgroups: fields?.local?.areaSubgroups
          .filter(areaSubgroup => values.place.areaSubgroup.includes(`${areaSubgroup.value}`))
          .map(areaSubgroup => areaSubgroup.value),
        equipmentTypes: fields?.local?.equipmentTypes
          .filter(equipmentType => values.place.equipmentsType.includes(`${equipmentType.value}`))
          .map(equipmentType => equipmentType.value),
        equipments: values?.place?.equipments.map(equipment => Number(equipment)),
      },
      inactivePlans: !values?.inactiveActivities?.includes('false'),
      appPerPages: values?.appPerPages?.includes('true'),
    };
  };
  const onSubmit = async values => {
    if (isPageDisabled) {
      return;
    }
    if (watch('reportType') === 'pdf') {
      const hasError = validateRequiredFieldsPDF(values);
      if (hasError) {
        return;
      }
      const payload = formatPayloadPDF(values);
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'loading',
          message: 'Gerando arquivo...',
        }),
      ]);
      return sendPDFByEmail(payload);
    }
    if (watch('reportType') === 'excel') {
      const hasError = validateRequiredFieldsExcel(values);
      if (hasError) {
        return;
      }
      setIsSendOcurrenceEmailSuccess(true);
      const payload = formatPayloadExcel(values);
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'loading',
          message: 'Gerando arquivo...',
        }),
      ]);
      return sendExcelByEmail(payload);
    }
  };
  const sendExcelByEmail = async payload => {
    let isGenerateReportSuccess = false;
    try {
      const { companyId, units, period, systems, status, activitiesPlans, inactivePlans } = payload;
      const { token, user } = authStorage;
      const emailObj = {
        login: user.name,
        site: 0,
        empresa: companyId,
        dataInicial: period.expectedDate.start,
        dataFinal: period.expectedDate.end,
        sistemas: systems,
        planoAtividades: activitiesPlans,
        status,
        token,
        listaSites: units,
        planosInativos: inactivePlans,
      };
      const response = await ActivitiesServices.sendExcelByEmail(emailObj);
      const { data } = response;
      if (!data) {
        setToastList(prevState => [
          ...prevState,
          createToast({
            type: 'error',
            message: 'Não há dados para os filtros selecionados',
          }),
        ]);
        return;
      }
      if (data === 'Relatório de atividades enviado para fila de processamento') {
        isGenerateReportSuccess = true;
        setIsSendOcurrenceEmailSuccess(true);
      } else {
        throw new Error('sendExcelByEmail unexpected response');
      }
    } catch (e) {
      createGenericErrorToast(setToastList);
    } finally {
      isGenerateReportSuccess && Sentry.captureMessage('Relatório Excel enviado');
    }
  };
  const sendPDFByEmail = async payload => {
    let isGenerateReportSuccess = false;
    try {
      const {
        companyId,
        inactivePlans,
        unit,
        period,
        systems,
        status,
        activitiesPlans,
        multiCheckbox,
        photos,
        place,
        people,
        notGroupPhotos,
        appPerPages,
      } = payload;
      const { token, user } = authStorage;
      const emailObj = {
        login: user.name,
        naoAgruparFotos: notGroupPhotos,
        unidadeManutencao: unit,
        empresa: companyId,
        dataPrevistaInicial: period?.expectedDate?.start,
        dataPrevistaFinal: period?.expectedDate?.end,
        dataRealizadaInicial: period?.accomplishedDate?.start,
        dataRealizadaFinal: period?.accomplishedDate?.end,
        sistemas: systems,
        custos: multiCheckbox.cost,
        materiaisUtilizados: multiCheckbox.materials,
        assinaturas: multiCheckbox.signatures,
        medicoes: multiCheckbox.measurements,
        justificativaAtividadesNaoRealizadas: multiCheckbox.justifications,
        fotos: photos,
        planoAtividades: activitiesPlans,
        status,
        token,
        fornecedores: people.providers[0],
        executores: people.executors,
        gruposAreas: place.areaGroups,
        subGrupoAreas: place.areaSubgroups,
        areas: place.areas,
        tipoEquipamentos: place.equipmentTypes,
        equipmentos: place.equipments,
        planosInativos: inactivePlans,
        umaAplicacaoPagina: appPerPages,
      };
      const response =
        watch('reportModel') === 'detailed'
          ? await ActivitiesServices.sendPDFByEmail(emailObj)
          : await ActivitiesServices.sendPDFSummaryByEmail(emailObj);
      const { data } = response;
      if (!data) {
        setToastList(prevState => [
          ...prevState,
          createToast({
            type: 'error',
            message: 'Não há dados para os filtros selecionados',
          }),
        ]);
        return;
      }
      if (data === 'Relatório de atividades pdf enviado para fila de processamento') {
        isGenerateReportSuccess = true;
        setIsSendOcurrenceEmailSuccess(true);
      } else if (data === 'Relatorio Duplicado') {
        setDuplicatedReport(true);
      } else {
        throw new Error('sendPDFByEmail unexpected response');
      }
    } catch (e) {
      createGenericErrorToast(setToastList);
      return;
    } finally {
      isGenerateReportSuccess && Sentry.captureMessage('Relatório PDF enviado');
    }
  };
  const sendEmail = () => {
    setIsSendOcurrenceEmailSuccess(false);
    setActive(true);
  };
  const closeModal = () => {
    setIsSendOcurrenceEmailSuccess(false);
    setActive(true);
  };
  const closeAtentionModal = () => {
    setDuplicatedReport(false);
  };
  const updateGeneratedReports = async e => {
    e.preventDefault();
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    const fieldsData = await ReportService.getGeneratedReports({
      usuarioId: user.id,
      tipos: [3, 4],
      token: authStorage.token,
    }).then(values => ({
      generatedReports: values?.data.sort((a, b) => {
        return a.emissao > b.emissao ? -1 : a.emissao < b.emissao ? 1 : 0;
      }),
    }));
    setFields(prevState => ({
      ...prevState,
      ...fieldsData,
    }));
    setActive(true);
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  };
  const updateGeneratedReports60S = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    const fieldsData = await ReportService.getGeneratedReports({
      usuarioId: user.id,
      tipos: [3, 4],
      token: authStorage.token,
    }).then(values => ({
      generatedReports: values?.data.sort((a, b) => {
        return a.emissao > b.emissao ? -1 : a.emissao < b.emissao ? 1 : 0;
      }),
    }));
    setFields(prevState => ({
      ...prevState,
      ...fieldsData,
    }));
    setActive(true);
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  };
  const [hasOnlyUnitsWithSubgroup, setHasOnlyUnitsWithSubgroup] = useState(true);
  const [hasOnlyUnitsWithGroup, setHasOnlyUnitsWithGroup] = useState(true);
  const isUnitSelected = getValues('unit') !== '';
  const isUnitSubgroupSelected = getValues('unitSubgroup') !== '';
  const isOnlyOneUnitGroupAvailable = fields?.unitGroups?.length === 1;
  const isOnlyOneUnitSubGroupAvailable = fields?.unitSubgroups?.length === 1;
  const doesNotHaveUnitGroup = fields?.unitGroups?.length === 0;
  const isUnitGroupDisabled =
    (isUnitSelected && isUnitSubgroupSelected) ||
    (isOnlyOneUnitGroupAvailable && hasOnlyUnitsWithGroup) ||
    isUnitSelected ||
    doesNotHaveUnitGroup;
  const isUnitSubgroupDisabled = isUnitSelected || (isOnlyOneUnitSubGroupAvailable && hasOnlyUnitsWithSubgroup);
  const isPageDisabled = !isEmailVerified;
  const testInputs = e => {
    const status = getValues('activities.status');
    const expectedDate = getValues('period.expectedDate');
    const accomplishedDate = getValues('period.accomplishedDate');
    if (status.length === 0 && accomplishedDate === '' && expectedDate === '') {
      e.preventDefault();
      setStatusRequired(true);
      setDateRequired(true);
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Um ou mais campos obrigatórios não foram preenchidos.',
        }),
      ]);
    } else if (accomplishedDate === '' && expectedDate === '' && status.length > 0) {
      e.preventDefault();
      setDateRequired(true);
      setStatusRequired(false);
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Por favor preencha um período.',
        }),
      ]);
    } else if (status.length === 0) {
      e.preventDefault();
      setStatusRequired(true);
      setDateRequired(false);
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Por favor preencha um status.',
        }),
      ]);
    } else {
      setStatusRequired(false);
      setDateRequired(false);
    }
  };
  const tabs = [
    {
      id: 0,
      title: 'Gerar relatório',
      content: (
        <>
          <ReportContainerColumn justifyContent="flex-end" gridColumnStart={2}>
            <ReportContainerFields>
              <FieldRow marginBottom="1rem">
                <FormGroup label="Formato do relatório" isRequired>
                  <StyledFormRadio>
                    <Radio text="PDF (texto e fotos)" id="reportPdf" name="reportType" value="pdf" ref={register()} />
                    <Radio
                      text="Excel (apenas texto)"
                      type="radio"
                      id="reportExcel"
                      name="reportType"
                      value="excel"
                      ref={register()}
                    />
                  </StyledFormRadio>
                </FormGroup>
                <Conditional when={watch('reportType') === 'pdf'}>
                  <FieldRow marginBottom="2rem" />
                  <FormGroup label="Modelo do relatório" isRequired>
                    <StyledFormRadio>
                      <Radio
                        text="Detalhado"
                        id="reportDetailed"
                        name="reportModel"
                        value="detailed"
                        ref={register()}
                      />
                      <Radio
                        text="Resumido"
                        type="radio"
                        id="reportSummary"
                        name="reportModel"
                        value="sumarry"
                        ref={register()}
                      />
                    </StyledFormRadio>
                  </FormGroup>
                </Conditional>
                <FieldRow marginBottom="2rem" />
                <FormGroup label="Grupo de unidades" disabled={isUnitGroupDisabled}>
                  <Controller
                    render={props => (
                      <Select
                        {...props}
                        size="small"
                        placeholder="Seleção opcional"
                        options={fields?.unitGroups}
                        disabled={isUnitGroupDisabled}
                        cleanFields={resetFieldValues}
                        setValueSubGroup={setValue}
                        defaultValue={isFirstMount ? props.defaultValue : ''}
                        onChange={value => {
                          props.onChange(value);
                          fetchData();
                        }}
                        ref={null}
                      />
                    )}
                    name="unitGroup"
                    control={control}
                    defaultValue={headerFilters?.unitGroups?.id || ''}
                  />
                </FormGroup>
              </FieldRow>
              <FieldRow marginBottom="1rem">
                <FormGroup label="Subgrupo de unidades" disabled={isUnitSubgroupDisabled}>
                  <Controller
                    render={props => (
                      <Select
                        {...props}
                        size="small"
                        placeholder="Seleção opcional"
                        disabled={isUnitSubgroupDisabled}
                        hasOnlyUnitsWithSubgroup={hasOnlyUnitsWithSubgroup}
                        options={fields?.unitSubgroups}
                        defaultValue={isFirstMount ? props.defaultValue : ''}
                        onChange={value => {
                          props.onChange(value);
                          fetchData();
                        }}
                        ref={null}
                      />
                    )}
                    name="unitSubgroup"
                    control={control}
                    defaultValue={headerFilters?.unitSubgroups?.id || ''}
                  />
                </FormGroup>
              </FieldRow>
              <FieldRow marginBottom="1rem">
                {watch('reportType') === 'excel' ? (
                  <FormGroup label="Unidades de manutenção" disabled={fields?.units?.length <= 0} isRequired>
                    <Controller
                      render={props => (
                        <MultiSelectExcel
                          {...props}
                          size="small"
                          placeholder="Seleção obrigatória"
                          options={fields?.units}
                          //defaultValue={isFirstMount ? props.defaultValue : ''}
                          onChange={value => {
                            props.onChange(value);
                            fetchData();
                          }}
                          onInputClear={() => {
                            setFields(prevFields => ({
                              unitGroups: prevFields.unitGroups,
                              unitSubgroups: prevFields.unitSubgroups,
                              units: [],
                            }));
                          }}
                          ref={null}
                        />
                      )}
                      name="units"
                      control={control}
                      defaultValue={headerFilters?.units?.id || []}
                      rules={{ required: true }}
                    />
                  </FormGroup>
                ) : (
                  <FormGroup label="Unidade de manutenção" disabled={fields?.units?.length <= 0} isRequired>
                    <Controller
                      render={props => (
                        <Select
                          {...props}
                          size="small"
                          placeholder="Seleção obrigatória"
                          options={fields?.units}
                          defaultValue={isFirstMount ? props.defaultValue : ''}
                          onChange={value => {
                            props.onChange(value);
                            fetchData();
                          }}
                          onInputClear={() => {
                            setFields(prevFields => ({
                              unitGroups: prevFields.unitGroups,
                              unitSubgroups: prevFields.unitSubgroups,
                              units: [],
                            }));
                          }}
                          ref={null}
                        />
                      )}
                      name="unit"
                      control={control}
                      defaultValue={headerFilters?.units?.id || ''}
                      rules={{ required: true }}
                    />
                  </FormGroup>
                )}
              </FieldRow>
              <FieldRow marginBottom="1rem">
                <FormGroup label="Sistema" disabled={!isUnitSelected}>
                  <Controller
                    render={props => (
                      <MultiSelect
                        {...props}
                        size="small"
                        placeholder="Seleção opcional"
                        options={fields?.systems}
                        disabled={!isUnitSelected}
                        onChange={value => {
                          props.onChange(value);
                          fetchData();
                        }}
                        ref={register}
                      />
                    )}
                    name="systems"
                    control={control}
                    defaultValue={[]}
                    rules={{ required: true }}
                  />
                </FormGroup>
              </FieldRow>
            </ReportContainerFields>
          </ReportContainerColumn>
          <LineSeparator show={watch('unit') || watch('units')} />
          {(!!watch('unit') || watch('units').length > 0) && !isLoading ? (
            <ReportContainerColumn>
              <ReportContainerFields>
                <Conditional when={watch('reportType') === 'excel'}>
                  <Accordion titulo="Período" isRequired>
                    <GridColumn size={1} columnGap="1rem" rowGap="1rem">
                      <FormGroup label="Data prevista" isRequired>
                        <Controller
                          render={props => (
                            <DateRangePicker
                              {...props}
                              maxDays={headerFilters?.companies?.id === 3802 ? 92 : 365}
                              data-cy="opening-of-occurrence-date-picker"
                              size="small"
                              placeholder="Seleção obrigatória"
                            />
                          )}
                          name="period.expectedDate"
                          control={control}
                          //defaultValue={''}
                          defaultValue={''}
                        />
                      </FormGroup>
                    </GridColumn>
                  </Accordion>
                  <FieldRow marginBottom="0.9rem" />
                  <Accordion titulo="Atividades">
                    <GridColumn size={1} columnGap="1rem" rowGap="1rem">
                      <FormGroup label="Exibir planos de atividades inativos">
                        <StyledFormRadio>
                          <Radio text="Sim" id="inactiveYes" name="inactiveActivities" value="true" ref={register()} />
                          <Radio
                            text="Não"
                            type="radio"
                            id="inactiveNo"
                            name="inactiveActivities"
                            value="false"
                            ref={register()}
                          />
                        </StyledFormRadio>
                      </FormGroup>
                      <FormGroup label="Planos de atividades">
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.activitiesPlans}
                              ref={null}
                              onChange={value => {
                                props.onChange(value);
                                //fetchData();
                                populateFields();
                              }}
                            />
                          )}
                          name="activities.plan"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      <FormGroup label="Status de atividades">
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              placeholder="Seleção opcional"
                              options={statusOptions}
                              ref={null}
                              onChange={value => {
                                props.onChange(value);
                                //fetchData();
                                populateFields();
                              }}
                            />
                          )}
                          name="activities.status"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                    </GridColumn>
                  </Accordion>
                </Conditional>
                <Conditional when={watch('reportType') === 'pdf'}>
                  <Accordion titulo="Período" isRequired>
                    <GridColumn size={1} columnGap="1rem" rowGap="1rem">
                      <FormGroup>
                        <PeriodWarning>Preencha pelo menos um dos campos abaixo:</PeriodWarning>
                      </FormGroup>
                      <FormGroup label="Data prevista">
                        <Controller
                          render={props => (
                            <DateRangePicker
                              {...props}
                              maxDays={90}
                              data-cy="opening-of-occurrence-date-picker"
                              size="small"
                              placeholder="Seleção obrigatória"
                              required={dateRequired}
                            />
                          )}
                          name="period.expectedDate"
                          control={control}
                          defaultValue={''}
                        />
                        {dateRequired ? (
                          <RequiredMessage>Preencha pelo menos um dos dois campos de Período.</RequiredMessage>
                        ) : (
                          ''
                        )}
                      </FormGroup>
                      <FormGroup label="Data realizada">
                        <Controller
                          render={props => (
                            <DateRangePicker
                              {...props}
                              maxDays={90}
                              data-cy="opening-of-occurrence-date-picker"
                              size="small"
                              placeholder="Seleção obrigatória"
                              required={dateRequired}
                            />
                          )}
                          name="period.accomplishedDate"
                          control={control}
                          defaultValue={''}
                        />
                        {dateRequired ? (
                          <RequiredMessage>Preencha pelo menos um dos dois campos de Período.</RequiredMessage>
                        ) : (
                          ''
                        )}
                      </FormGroup>
                    </GridColumn>
                    <FieldRow marginBottom="0.9rem" />
                  </Accordion>
                  <FieldRow marginBottom="0.9rem" />
                  <Accordion titulo="Atividades" isRequired>
                    <GridColumn size={1} columnGap="1rem" rowGap="1rem">
                      <FormGroup label="Exibir planos de atividades inativos">
                        <StyledFormRadio>
                          <Radio text="Sim" id="inactiveYes" name="inactiveActivities" value="true" ref={register()} />
                          <Radio
                            text="Não"
                            type="radio"
                            id="inactiveNo"
                            name="inactiveActivities"
                            value="false"
                            ref={register()}
                          />
                        </StyledFormRadio>
                      </FormGroup>
                      <FormGroup label="Planos de atividades">
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.activitiesPlans}
                              ref={null}
                            />
                          )}
                          name="activities.plan"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      <FormGroup label="Status de atividades" isRequired>
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              placeholder="Seleção obrigatória"
                              options={statusOptions}
                              ref={null}
                              required={statusRequired}
                            />
                          )}
                          name="activities.status"
                          control={control}
                          defaultValue={[]}
                        />
                        {statusRequired ? <RequiredMessage>Preenchimento obrigatório.</RequiredMessage> : ''}
                      </FormGroup>
                      <FormGroup label="Exibir no relatório:">
                        <Controller
                          render={props => (
                            <MultiCheckbox
                              {...props}
                              options={
                                watch('reportModel') === 'detailed' ? checkBoxOptionsDetailed : checkBoxOptionsSummary
                              }
                              register={register}
                              watch={watch}
                              ref={null}
                              onChange={value => {
                                props.onChange(value);
                              }}
                            />
                          )}
                          name="activities.multiCheckbox"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      {watch('reportModel') === 'detailed' && (
                        <FormGroup label="Visualização das aplicações (equipamentos, áreas ou unidades):">
                          <StyledFormRadio>
                            <Radio
                              text="Várias aplicações por página"
                              id="moreThanOnePerPage"
                              name="appPerPages"
                              value="false"
                              ref={register()}
                            />
                            <Radio
                              text="Uma aplicação por página"
                              type="radio"
                              id="onePerPage"
                              name="appPerPages"
                              value="true"
                              ref={register()}
                            />
                          </StyledFormRadio>
                        </FormGroup>
                      )}
                    </GridColumn>
                    <FieldRow marginBottom="0.9rem" />
                  </Accordion>
                  <FieldRow marginBottom="0.9rem" />
                  <Accordion titulo="Pessoas">
                    <GridColumn size={1} columnGap="1rem" rowGap="1rem">
                      <FormGroup label="Fornecedores" disabled={fields?.people?.providers?.length <= 0}>
                        <Controller
                          render={props => (
                            <Select
                              {...props}
                              size="small"
                              placeholder="Seleção opcional"
                              disabled={fields?.people?.providers?.length <= 0}
                              options={fields?.people?.providers}
                              ref={null}
                              onChange={value => {
                                props.onChange(value);
                                populateFields();
                              }}
                            />
                          )}
                          name="people.providers"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      <FormGroup label="Executores" disabled={fields?.people?.executors?.length <= 0}>
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              disabled={fields?.people?.executors?.length <= 0}
                              placeholder="Seleção opcional"
                              options={fields?.people?.executors}
                              ref={null}
                            />
                          )}
                          name="people.executors"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                    </GridColumn>
                    <FieldRow marginBottom="0.9rem" />
                  </Accordion>
                  <FieldRow marginBottom="0.9rem" />
                  <Accordion titulo="Local">
                    <GridColumn size={1} columnGap="1rem" rowGap="1rem">
                      <FormGroup label="Grupo de áreas" disabled={fields?.local?.areaGroups?.length <= 0}>
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              placeholder="Seleção opcional"
                              disabled={fields?.local?.areaGroups?.length <= 0}
                              options={fields?.local?.areaGroups}
                              ref={null}
                              onChange={value => {
                                props.onChange(value);
                                populateFields();
                              }}
                            />
                          )}
                          name="place.areaGroup"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      <FormGroup label="Subgrupo de áreas" disabled={fields?.local?.areaSubgroups?.length <= 0}>
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              placeholder="Seleção opcional"
                              disabled={fields?.local?.areaSubgroups?.length <= 0}
                              options={fields?.local?.areaSubgroups}
                              ref={null}
                              onChange={value => {
                                props.onChange(value);
                                populateFields();
                              }}
                            />
                          )}
                          name="place.areaSubgroup"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      <FormGroup label="Áreas" disabled={fields?.local?.areas?.length <= 0}>
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              disabled={fields?.local?.areas?.length <= 0}
                              placeholder="Seleção opcional"
                              options={fields?.local?.areas}
                              ref={null}
                              onChange={value => {
                                props.onChange(value);
                                populateFields();
                              }}
                            />
                          )}
                          name="place.area"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      <FormGroup label="Tipo de equipamento" disabled={fields?.local?.equipmentTypes?.length <= 0}>
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              disabled={fields?.local?.equipmentTypes?.length <= 0}
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.local?.equipmentTypes}
                              ref={null}
                              onChange={value => {
                                props.onChange(value);
                                populateFields();
                              }}
                            />
                          )}
                          name="place.equipmentsType"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      <FormGroup label="Equipamentos" disabled={fields?.local?.equipments?.length <= 0}>
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              disabled={fields?.local?.equipments?.length <= 0}
                              placeholder="Seleção opcional"
                              options={fields?.local?.equipments}
                              ref={null}
                            />
                          )}
                          name="place.equipments"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                    </GridColumn>
                  </Accordion>
                </Conditional>
                <FormGroup>
                  <FieldRow marginBottom="16px" />
                  <StyledFooter>
                    <StyledContainerFooter>
                      <StyledContentWrapper>
                        <StyledActions>
                          <ExportButton data-cy="export-audit-report-button" type="submit" onClick={e => testInputs(e)}>
                            EXPORTAR RELATÓRIO
                          </ExportButton>
                        </StyledActions>
                      </StyledContentWrapper>
                    </StyledContainerFooter>
                  </StyledFooter>
                </FormGroup>
              </ReportContainerFields>
            </ReportContainerColumn>
          ) : (
            <ReportContainerFillFields />
          )}
        </>
      ),
    },
    {
      id: 1,
      title: 'Relatórios gerados',
      content: (
        <>
          <ReportTable
            title="Histórico dos últimos 90 dias:"
            columns={columns}
            data={dataTable}
            updateGeneratedReports={updateGeneratedReports}
          />
        </>
      ),
      propertyContent: 'tabela',
    },
  ];
  const knowMorePage = e => {
    e.preventDefault();
    window.open('https://leankeep.movidesk.com/kb/pt-br/article/376042/relatorio-de-atividades?ticketId=&q=');
  };
  const queryString = useLocation().search;
  const relgeradosId = Number(new URLSearchParams(queryString).get('relGerados'));
  const activeTab = useState(relgeradosId === 1 ? tabs[1] : tabs[0]);
  const [autoReload, setAutoReload] = useState(tabs[0].id);
  useEffect(() => {
    const intervalUpdate = setInterval(async () => {
      if (fields.generatedReports.some(element => element.status === 1 || element.status == 3) && autoReload === 1) {
        await updateGeneratedReports60S();
      }
    }, 60000);
    return () => clearInterval(intervalUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoReload]);

  const emailNotVerifiedElement = (
    <EmailNotVerifiedWarningContainer>
      <svg width="80" height="88" viewBox="0 0 80 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.75436e-05 30.1139V57.8454C-0.00416577 60.7046 0.739864 63.5143 2.15712 65.9914C3.57437 68.4686 5.61477 70.5256 8.07267 71.9552L31.9273 85.8209C34.3817 87.2485 37.1659 88 40 88C42.8341 88 45.6183 87.2485 48.0727 85.8209L71.9273 71.9552C74.3852 70.5256 76.4256 68.4686 77.8429 65.9914C79.2601 63.5143 80.0042 60.7046 80 57.8454V30.1139C79.9971 27.2617 79.2497 24.4603 77.8328 21.9909C76.4159 19.5216 74.3793 17.471 71.9273 16.0448L48.0727 2.17907C45.6183 0.751537 42.8341 0 40 0C37.1659 0 34.3817 0.751537 31.9273 2.17907L8.07267 16.2481C5.65006 17.6568 3.63231 19.6755 2.21715 22.1062C0.802002 24.5368 0.0379999 27.2962 1.75436e-05 30.1139Z"
          fill="#EAE8E5"
        />
        <path
          d="M58.1797 39.5833C56.293 41.0833 53.9141 42.9167 45.5469 49.0833C43.9062 50.3333 40.8711 53.0833 38 53.0833C35.0469 53.0833 32.0938 50.3333 30.3711 49.0833C22.0039 42.9167 19.625 41.0833 17.7383 39.5833C17.4102 39.3333 17 39.5833 17 40V57C17 59.25 18.7227 61 20.9375 61H55.0625C57.1953 61 59 59.25 59 57V40C59 39.5833 58.5078 39.3333 58.1797 39.5833ZM38 50.3333C39.8867 50.4167 42.5938 47.9167 43.9883 46.9167C54.8984 38.9167 55.7188 38.1667 58.1797 36.1667C58.6719 35.8333 59 35.25 59 34.5833V33C59 30.8333 57.1953 29 55.0625 29H20.9375C18.7227 29 17 30.8333 17 33V34.5833C17 35.25 17.2461 35.8333 17.7383 36.1667C20.1992 38.1667 21.0195 38.9167 31.9297 46.9167C33.3242 47.9167 36.0312 50.4167 38 50.3333Z"
          fill="#AAAAAA"
        />
        <circle cx="56" cy="32" r="13.5" fill="#EC6655" stroke="#EAE8E5" strokeWidth="3" />
        <path
          d="M53.334 24.7439L53.8188 31.3714C53.8188 31.7908 54.0613 32.0005 54.5461 32.0005H57.4552C57.94 32.0005 58.1825 31.7908 58.1825 31.3714L58.6673 24.7439C58.6673 24.5152 58.5865 24.3436 58.4249 24.2292C58.3037 24.0767 58.1421 24.0005 57.94 24.0005H54.0613C53.8592 24.0005 53.6774 24.0767 53.5158 24.2292C53.3946 24.3436 53.334 24.5152 53.334 24.7439Z"
          fill="white"
        />
        <path
          d="M56.0007 34.6675C56.735 34.6675 57.3533 34.938 57.8557 35.4791C58.3968 35.9815 58.6673 36.5998 58.6673 37.3341C58.6673 38.0684 58.3968 38.7061 57.8557 39.2472C57.3533 39.7496 56.735 40.0008 56.0007 40.0008C55.2664 40.0008 54.6287 39.7496 54.0876 39.2472C53.5852 38.7061 53.334 38.0684 53.334 37.3341C53.334 36.5998 53.5852 35.9815 54.0876 35.4791C54.6287 34.938 55.2664 34.6675 56.0007 34.6675Z"
          fill="white"
        />
      </svg>
      <div>Para exportar o relatório é necessário ter um e-mail válido cadastrado no seu perfil.</div>
      <span>Se for preciso peça a ajuda de um administrador ou fale conosco.</span>
      <Button theme="primary" onClick={goback}>
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 5H1" stroke={colors.white} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5 1L1 5L5 9" stroke={colors.white} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        Voltar
      </Button>
    </EmailNotVerifiedWarningContainer>
  );
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  return (
    <>
      {isLoading && <Loading />}
      <PageWrapper background={colors.pampas}>
        <MobileNavbar />
        {/* <Menu /> */}
        <NewMenu setIsSubMenuOpen={setIsSubMenuOpen} />
        <HeaderFiltersPage location={pathname} isSubMenuOpen={isSubMenuOpen}>
          <Header>
            <GoBackButton onClick={() => goback()}>
              <svg width="12" height="10" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.75258 0.341509C10.1163 0.757146 10.0741 1.38891 9.6585 1.75259L2.51859 8.00001L9.6585 14.2474C10.0741 14.6111 10.1163 15.2429 9.75258 15.6585C9.3889 16.0742 8.75713 16.1163 8.3415 15.7526L0.341495 8.75259C0.12448 8.5627 0 8.28838 0 8.00001C0 7.71165 0.12448 7.43733 0.341495 7.24744L8.3415 0.247437C8.75713 -0.116245 9.3889 -0.0741276 9.75258 0.341509Z"
                  fill="black"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 8C0 7.44772 0.447715 7 1 7H23C23.5523 7 24 7.44772 24 8C24 8.55228 23.5523 9 23 9H1C0.447715 9 0 8.55228 0 8Z"
                  fill="black"
                />
              </svg>
            </GoBackButton>
            <HeaderName>Relatório de atividades</HeaderName>
            <KnowMoreButton onClick={e => knowMorePage(e)}>
              SAIBA MAIS
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.56522 0C6.98896 0 6.52174 0.467217 6.52174 1.04348C6.52174 1.61974 6.98896 2.08696 7.56522 2.08696H8.4375L5.5231 5.00136C5.11562 5.40884 5.11562 6.06942 5.5231 6.4769C5.72658 6.68038 5.994 6.78261 6.26087 6.78261C6.52774 6.78261 6.79516 6.68038 6.99864 6.4769L9.91304 3.5625V4.43478C9.91304 5.01104 10.3803 5.47826 10.9565 5.47826C11.5328 5.47826 12 5.01104 12 4.43478V1.04348C12 0.467217 11.5328 0 10.9565 0H7.56522ZM2.34783 0.521739C1.05313 0.521739 0 1.57513 0 2.86957V9.65217C0 10.9466 1.05313 12 2.34783 12H9.13043C10.4251 12 11.4783 10.9466 11.4783 9.65217V8.54348C11.4783 7.96722 11.011 7.5 10.4348 7.5C9.85852 7.5 9.3913 7.96722 9.3913 8.54348V9.65217C9.3913 9.79591 9.27444 9.91304 9.13043 9.91304H2.34783C2.20383 9.91304 2.08696 9.79591 2.08696 9.65217V2.86957C2.08696 2.72583 2.20383 2.6087 2.34783 2.6087H3.45652C4.03278 2.6087 4.5 2.14148 4.5 1.56522C4.5 0.988957 4.03278 0.521739 3.45652 0.521739H2.34783Z"
                  fill="#F38A00"
                />
              </svg>
            </KnowMoreButton>
          </Header>
          <ReportContainerTabs
            tabs={tabs}
            setAutoReload={setAutoReload}
            isPageDisabled={isPageDisabled}
            emailNotVerifiedElement={emailNotVerifiedElement}
            activeTab={activeTab[0].id}
            activeAfterSendEmail={active}
            setActiveAfterSendEmail={setActive}
            cleanFields={resetFieldValues}
            exportTitle="Exportar Relatório"
            onSubmit={handleSubmit(onSubmit)}
            refInputFilterName={register({
              required: true,
            })}
            renderFooter={false}
          >
            {isPageDisabled ? emailNotVerifiedElement : tabs}
          </ReportContainerTabs>
        </HeaderFiltersPage>
      </PageWrapper>
      <Toast toastList={toastList} autoDelete dismissTime={5000} />
      <SendEmailModal
        hasHeader
        title="Estamos preparando o seu relatório"
        icon={<EmailSuccessIcon />}
        size={{ width: '618px' }}
        success={true}
        open={isSendOcurrenceEmailSuccess}
        closeModal={() => closeModal()}
      >
        <p>
          Assim que estiver pronto, o arquivo ficará disponível na aba de “Relatórios gerados” e enviaremos um aviso
          para o e-mail cadastrado em sua conta.
        </p>
        <Button theme="primary" onClick={() => sendEmail()}>
          Entendi
        </Button>
      </SendEmailModal>
      <AtentionModal
        hasHeader
        title="O relatório já está sendo gerado"
        icon={<EmailAtentionIcon />}
        size={{ width: '554px' }}
        success={true}
        open={duplicatedReport}
        closeModal={() => setDuplicatedReport(false)}
      >
        <p>Já existe um relatório com esses dados sendo gerado, portanto essa nova requisição foi cancelada.</p>
        <Button theme="primary" onClick={() => closeAtentionModal()}>
          Entendi
        </Button>
      </AtentionModal>
    </>
  );
};
export { Activities };
